import React from 'react'
import CareerLayout from '../../components/careerLayout'

export default function TestAutomationEngineer () {
  return (
    <CareerLayout title='Test automation engineer' location='Remote'>
      <h2>Overview</h2>
      <p className='mb'>As a test automation engineer at Trexity, you’ll be part of a small team and will be working with and testing many areas of our platform. You will help improve the existing testing infrastructure, define testing and quality standards, and write tests that exercise server-bound and device-bound features. You will collaborate closely with the operations and engineering teams, taking part in release planning, and acceptance criteria planning.</p>

      <h2>What you might do</h2>
      <ul className='trx-bullet mb'>
        <li>Improve testing infrastructure so automated tests help keep the bar for quality high</li>
        <li>Participate in release planning so acceptance criteria is well known and planned as part of deployment</li>
        <li>Help increase test coverage by writing system tests that improve overall platform stability</li>
        <li>Help write end-to-end tests from the perspective of the user to ensure the system behaves as the user expects</li>
        <li>Help improve developer productivity by writing or sourcing internal testing tools and aids</li>
        <li>Resolve support requests as needed</li>
      </ul>

      <h2>Experience and skills</h2>
      <ul className='trx-bullet mb'>
        <li>At least 5 years of professional experience working on a customer-facing product</li>
        <li>You either know or are able to demonstrate that you can master the following: JavaScript and NodeJS, React and React Native</li>
        <li>Experience writing verified code using testing tools such as Mocha, WDIO, Detox, or Cypress</li>
        <li>Experience working in a fast growth environment</li>
        <li>Experience with CI tools (e.g. Github Actions, Buildkite)</li>
      </ul>

      <h2>Benefits</h2>
      <ul className='trx-bullet mb'>
        <li>Equal opportunity employer</li>
        <li>Full health benefits from day one</li>
        <li>3 weeks vacation</li>
        <li>Employee Share Option Plan</li>
      </ul>
    </CareerLayout>
  )
}
